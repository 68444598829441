jQuery.noConflict();
(function($) {
$( document ).ready(function() {
// andherewego.exe


// Home Form Hamsa slide-in
/*
var $animation_elements = $('.hamsa-left');
var $window = $(window);
function check_if_in_view() {
	var window_height = $window.height();
	var window_top_position = $window.scrollTop();
	var window_bottom_position = (window_top_position + window_height);
	$.each($animation_elements, function() {
		var $element = $(this);
		var element_height = $element.outerHeight();
		var element_top_position = $element.offset().top;
		var element_bottom_position = (element_top_position + element_height);
		//check to see if this current container is within viewport
		if ((element_bottom_position >= window_top_position) &&
			(element_top_position <= window_bottom_position)) {
		  $element.addClass('in-view');
		} else {
		  $element.removeClass('in-view');
		}
	});
}
$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll', check_if_in_view);
*/



// Slick Sliders
$('#intro-words').slick({
	infinite: true,
	fade: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	pauseOnHover: false,
	autoplay: true,
	speed: 1000,
	autoplaySpeed: 3000
});
$('.intro-slider').slick({
	infinite: true,
	fade: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	pauseOnHover: false,
	autoplay: true,
	speed: 1000,
	autoplaySpeed: 2000,
	dots: true,
	prevArrow: $('.home-team .arrow.prev'),
	nextArrow: $('.home-team .arrow.next')
});
$('#form-slider').slick({
	infinite: true,
	fade: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	pauseOnHover: false,
	autoplay: true,
	speed: 4000,
	autoplaySpeed: 3000
});
$('.reviews-slider').slick({
	infinite: true,
	slidesToShow: 2,
	slidesToScroll: 1,
	prevArrow: $('#homeReviews .arrow.prev'),
	nextArrow: $('#homeReviews .arrow.next'),
	responsive: [{
		breakpoint: 640,
			settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		}
    }]
});


// Home Services Menu
$('#homeServices .dd-button').click(function(event) {
	event.preventDefault();
	$('#homeServices .dd-menu').toggleClass("open");
});
$('#homeServices .dd-menu li.tabs-title').click(function(event) {
	event.preventDefault();
	$('#homeServices .dd-menu').removeClass("open");
});

// Navigation
$('.popup_menu').click(function(event) {
	event.preventDefault();
	$('body').toggleClass('nav_opened');
});

// Mobile Subnav
$('.subnav-toggle').click(function() {
	$('.widget_nav_menu').slideToggle();
	$(this).toggleClass('active');
});

// Analytics event tracking
$('.menu-item-18336').click(function() {
	ga('send', 'event', 'New Guest Offer', 'Download', 'Subnavigation');
});


// Initialize Team page

	jQuery( document ).ready(function() {
		jQuery.ajax({
			url: window.location.origin + '/people/tim-belcher/',
			type: 'POST',
			success: function(html) {
				jQuery('#ifrm').html(html);
			}
		});
	});


/*******************************
// Accordion Navigation
*******************************/
function closeNav(){
	$('.goback').removeClass('on');
	var listitems = $('.navScreen_holder_inner > ul>li.menu-item');    
	if(listitems.hasClass('collapse')) {
		setTimeout(function() {
			listitems.removeClass('collapse').css('z-index', '3');
			listitems.removeClass('expanded');
			$('.navScreen_holder_inner .social').fadeToggle();
		}, 400);
	}
	listitems.find('ul').removeClass('open');
}
$('.navScreen_holder_inner > ul > li.menu-item-has-children > a').click(function(event){
	$('.navScreen_holder_inner .social').fadeToggle();
	var mainitem = $(this).parent();
	var listitems = $('.navScreen_holder_inner > ul>li.menu-item').not(mainitem);
	event.preventDefault();
	$(mainitem).addClass('expanded');
	listitems.addClass('collapse').delay(100).css('z-index', '-99');
	if($(mainitem).find('ul').hasClass('open')) {
		closeNav();
	} else {
		setTimeout(function() {
			$(mainitem).find('ul').addClass('open');
		}, 400);
	}
		setTimeout(function() {
	$('.goback').delay(1000).addClass('on');
		}, 400);
});
$('.goback').click(function(event){ closeNav(); });



/*******************************
// mobile subnavigation
*******************************/
$('.opensub').click(function(event){
	$(this).toggleClass('open');
	$('#sidenav').slideToggle('fast');
	if($('#sidenav').hasClass('open')) {
		$('#sidenav').removeClass('open');
	} else {
		$('#sidenav').addClass('open');
	}
});


/*******************************
// join our team
*******************************/
$('.accordion').click(function(event){
	$(this).next('div').slideToggle('fast');
});




/*******************************
// foxycart gift cards equalizer fix
*******************************/
$('#gift_delivery').click(function() {
	setTimeout(function(){
		$('[data-equalizer]').foundation('_reflow');
	}, 500);
});



// end it all
});
})(jQuery);